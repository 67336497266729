'use client';
import cn from 'classnames';
import styles from './JumpingText.module.scss';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

type JumpingTextProps = {
  className?: string;
  text: string;
};

type CharacterProps = {
  char: string;
  index: number;
  isHovered: boolean;
  isNearby: boolean;
  setHoveredChar: Dispatch<SetStateAction<number>>;
};

const NUM_LAYERS = 6;

const calculateTransform = (
  isHovered: boolean,
  isNearby: boolean,
  index: number,
  springStep: number = 0,
) => {
  const baseTransforms = [
    `translate(${1 + springStep}px, ${-1 - springStep}px)`,
    `translate(${2 + springStep}px, ${-2 - springStep}px)`,
    `translate(${3 + springStep}px, ${-3 - springStep}px)`,
    `translate(${4 + springStep}px, ${-4 - springStep}px)`,
    `translate(${5 + springStep}px, ${-5 - springStep}px)`,
    `translate(${6 + springStep}px, ${-6 - springStep}px)`,
    `translate(${7 + springStep}px, ${-7 - springStep}px)`,
  ];

  if (isHovered) {
    return `translate(${2 * (index + 1) + springStep}px, -${
      2 * (index + 1) + springStep
    }px)`;
  }

  if (isNearby) {
    return `translate(${1.5 * (index + 1) + springStep}px, -${
      1.5 * (index + 1) + springStep
    }px)`;
  }

  return baseTransforms[index];
};

const Character = ({
  char,
  index,
  isHovered,
  isNearby,
  setHoveredChar,
}: CharacterProps) => {
  const [springStep, setSpringStep] = useState(0);

  useEffect(() => {
    let timeout1: NodeJS.Timeout;
    let timeout2: NodeJS.Timeout;

    if (isHovered || isNearby) {
      setSpringStep(3);
      timeout1 = setTimeout(() => {
        setSpringStep(-1);
        timeout2 = setTimeout(() => {
          setSpringStep(0);
        }, 300);
      }, 200);
    } else {
      setSpringStep(0);
    }

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  }, [isHovered, isNearby]);

  useEffect(() => {
    let timeout2: NodeJS.Timeout;
    setSpringStep(5);
    const timeout1 = setTimeout(() => {
      setSpringStep(-1);
      timeout2 = setTimeout(() => {
        setSpringStep(0);
      }, 0);
    }, 200 * (index + 1));

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  }, [index]);

  const layers = Array.from({ length: NUM_LAYERS });

  const getSpringStep = (index: number) => {
    switch (index) {
      default:
        return springStep;
    }
  };

  return (
    <div
      key={char + index}
      className={styles.char}
      onMouseOver={() => setHoveredChar(index)}
      style={{ zIndex: 10 - index }}
    >
      <span className={styles.bottom}>{char}</span>
      <span
        className={styles.moving}
        style={{
          transform: calculateTransform(isHovered, isNearby, 0, 0),
        }}
      >
        {char}
      </span>
      {layers.map((_, layerIndex) => (
        <span
          key={layerIndex}
          className={styles.moving}
          style={{
            transform: calculateTransform(
              isHovered,
              isNearby,
              layerIndex,
              getSpringStep(layerIndex),
            ),
          }}
        >
          {char}
        </span>
      ))}
      <span
        className={styles.top}
        style={{
          transform: calculateTransform(isHovered, isNearby, 6, springStep),
        }}
      >
        {char}
      </span>
    </div>
  );
};

export function JumpingText({ className, text }: JumpingTextProps) {
  const [hoveredChar, setHoveredChar] = useState<number>(-3);

  const wrapperClassName = cn(styles.wrapper, className);

  return (
    <div className={wrapperClassName} onMouseLeave={() => setHoveredChar(-3)}>
      {text.split('').map((char, index) => {
        const isHovered = hoveredChar === index;
        const isNearby = hoveredChar - 1 === index || hoveredChar + 1 === index;

        return (
          <Character
            key={char + index}
            char={char}
            index={index}
            isHovered={isHovered}
            isNearby={isNearby}
            setHoveredChar={setHoveredChar}
          />
        );
      })}
    </div>
  );
}
