type ArrowProps = {
  className?: string;
};

export function Arrow({ className }: ArrowProps) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="2048.000000pt"
      height="2048.000000pt"
      viewBox="0 0 2048.000000 2048.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,2048.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M10155 19349 c-482 -45 -859 -195 -1108 -442 -138 -137 -232 -295
   -291 -489 -51 -170 -60 -234 -60 -428 1 -134 6 -221 20 -295 103 -570 383
   -1117 849 -1660 414 -482 921 -900 1995 -1645 898 -622 1169 -825 1394 -1040
   207 -199 352 -420 401 -610 23 -90 23 -347 0 -427 -41 -140 -141 -290 -253
   -379 -141 -110 -369 -193 -602 -219 -30 -3 -71 -8 -90 -10 -19 -3 -57 -8 -85
   -11 -27 -3 -59 -7 -70 -9 -156 -24 -430 -66 -465 -70 -25 -3 -54 -7 -66 -9
   -12 -2 -45 -7 -75 -11 -30 -3 -115 -17 -189 -30 -408 -73 -781 -94 -1680 -95
   -565 0 -734 3 -1545 25 -526 14 -735 18 -1095 18 -574 0 -1325 -20 -1795 -49
   -49 -3 -110 -6 -135 -8 -25 -1 -70 -5 -100 -9 -43 -6 -582 -35 -650 -36 -8 0
   -62 -3 -120 -6 -97 -5 -180 -9 -415 -20 -255 -12 -421 -22 -520 -31 -38 -3
   -83 -7 -100 -8 -16 -1 -50 -5 -75 -9 -25 -4 -144 -19 -265 -32 -388 -45 -401
   -47 -709 -116 -771 -172 -1172 -440 -1410 -941 -60 -125 -92 -225 -125 -388
   -12 -58 -24 -116 -26 -130 -6 -29 -13 -99 -21 -220 -5 -70 6 -263 20 -370 12
   -86 59 -337 76 -403 23 -87 89 -265 125 -336 49 -97 147 -230 227 -310 230
   -227 578 -394 1049 -504 107 -25 208 -48 224 -51 17 -3 41 -8 55 -10 29 -6 74
   -12 145 -21 28 -3 84 -10 125 -15 100 -13 445 -52 495 -56 206 -18 559 -23
   830 -11 216 9 199 8 550 40 154 14 314 29 355 32 72 6 146 13 220 20 19 2 65
   6 103 9 37 4 89 9 115 12 26 2 64 7 85 9 20 2 45 6 55 9 16 5 395 48 537 61
   39 4 84 9 100 10 17 2 50 6 75 9 107 11 157 17 180 21 91 14 406 55 460 60 36
   3 83 8 105 10 22 2 65 7 95 10 30 3 71 8 90 10 52 6 140 15 183 20 20 2 45 6
   55 9 18 6 400 51 517 61 312 28 494 48 555 60 31 6 404 43 520 51 47 3 103 7
   125 9 22 2 74 6 115 9 186 15 321 26 365 31 110 13 358 35 455 40 248 15 419
   24 565 30 55 3 152 7 215 10 63 3 165 7 225 10 97 3 223 10 380 19 28 2 122 8
   210 14 88 6 234 14 325 17 91 3 224 8 295 11 180 7 417 15 905 29 290 8 520
   13 853 18 l307 5 67 -52 c114 -88 246 -262 319 -419 22 -49 25 -74 31 -204 l5
   -148 -40 -120 c-22 -66 -61 -167 -86 -225 -25 -58 -77 -186 -116 -285 -217
   -545 -485 -910 -982 -1331 -238 -203 -604 -456 -1183 -819 -792 -498 -1093
   -721 -1431 -1066 -336 -343 -536 -673 -600 -988 -25 -126 -16 -338 19 -442 75
   -221 259 -410 537 -549 120 -61 394 -148 515 -165 23 -3 52 -8 65 -10 14 -3
   63 -8 110 -11 47 -3 91 -7 99 -10 18 -6 366 -5 436 1 69 6 154 14 190 19 17 2
   53 7 80 11 84 11 364 81 480 120 211 70 557 232 770 360 239 143 580 383 837
   587 363 290 565 470 1453 1293 143 132 454 418 690 635 1130 1036 1533 1445
   1885 1915 320 428 506 770 845 1560 267 622 419 1027 464 1239 55 258 60 632
   16 1026 -2 19 -7 62 -10 95 -3 33 -8 76 -10 95 -3 19 -7 58 -10 85 -18 157
   -96 520 -141 655 -106 313 -228 507 -705 1125 -64 83 -253 335 -420 560 -682
   919 -889 1159 -1764 2044 -560 567 -612 622 -1110 1177 -909 1013 -1532 1564
   -2450 2166 -672 441 -1370 843 -1730 996 -176 75 -420 156 -549 182 -237 48
   -552 69 -761 49z"
        />
        <path
          d="M6628 12093 c61 -2 163 -2 225 0 61 1 11 3 -113 3 -124 0 -174 -2
   -112 -3z"
        />
        <path
          d="M7413 12093 c64 -2 170 -2 235 0 64 1 11 3 -118 3 -129 0 -182 -2
   -117 -3z"
        />
        <path d="M6188 12083 c29 -2 74 -2 100 0 26 2 2 3 -53 3 -55 0 -76 -1 -47 -3z" />
        <path d="M8008 12083 c29 -2 74 -2 100 0 26 2 2 3 -53 3 -55 0 -76 -1 -47 -3z" />
        <path d="M10868 12083 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path d="M5893 12073 c15 -2 39 -2 55 0 15 2 2 4 -28 4 -30 0 -43 -2 -27 -4z" />
        <path d="M8357 12073 c18 -2 50 -2 70 0 21 2 7 4 -32 4 -38 0 -55 -2 -38 -4z" />
        <path d="M10748 12073 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path d="M5663 12063 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z" />
        <path d="M8683 12063 c15 -2 39 -2 55 0 15 2 2 4 -28 4 -30 0 -43 -2 -27 -4z" />
        <path d="M10588 12063 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path d="M5448 12053 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path d="M9067 12053 c18 -2 50 -2 70 0 21 2 7 4 -32 4 -38 0 -55 -2 -38 -4z" />
        <path d="M10353 12053 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z" />
        <path d="M4518 12003 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z" />
        <path d="M4333 11993 c15 -2 37 -2 50 0 12 2 0 4 -28 4 -27 0 -38 -2 -22 -4z" />
        <path d="M4143 11983 c15 -2 37 -2 50 0 12 2 0 4 -28 4 -27 0 -38 -2 -22 -4z" />
        <path d="M3958 11973 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z" />
        <path d="M3773 11963 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z" />
        <path d="M3608 11953 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path d="M20412 10040 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
        <path d="M20422 9920 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z" />
        <path d="M92 9575 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
        <path d="M92 9335 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
        <path d="M20422 9305 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
        <path d="M14053 7613 c20 -2 54 -2 75 0 20 2 3 4 -38 4 -41 0 -58 -2 -37 -4z" />
        <path d="M13618 7603 c17 -2 47 -2 65 0 17 2 3 4 -33 4 -36 0 -50 -2 -32 -4z" />
        <path d="M13288 7593 c17 -2 47 -2 65 0 17 2 3 4 -33 4 -36 0 -50 -2 -32 -4z" />
        <path d="M12987 7583 c18 -2 50 -2 70 0 21 2 7 4 -32 4 -38 0 -55 -2 -38 -4z" />
        <path d="M12713 7573 c20 -2 54 -2 75 0 20 2 3 4 -38 4 -41 0 -58 -2 -37 -4z" />
        <path d="M12457 7563 c18 -2 50 -2 70 0 21 2 7 4 -32 4 -38 0 -55 -2 -38 -4z" />
        <path d="M12238 7553 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z" />
        <path d="M11148 7503 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path d="M10928 7493 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
        <path d="M10733 7483 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z" />
        <path d="M10548 7473 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z" />
        <path d="M10378 7463 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z" />
        <path d="M10213 7453 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z" />
        <path d="M10078 7443 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
        <path d="M9118 7363 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path d="M9008 7353 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path d="M8898 7343 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path d="M7858 7233 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path d="M4968 6903 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path d="M4858 6893 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path
          d="M3628 6833 c57 -2 147 -2 200 0 53 1 7 3 -103 3 -110 0 -154 -2 -97
   -3z"
        />
        <path d="M11638 763 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z" />
        <path d="M12078 763 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z" />
      </g>
    </svg>
  );
}
