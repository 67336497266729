'use client';
import cn from 'classnames';
import styles from './Button.module.scss';
import { ReactNode, useEffect, useState } from 'react';
import { useTabletWidth } from '@/hooks/useTabletWidth';

export enum ButtonVariant {
  Fit = 'fit',
  Full = 'full',
}

export enum ButtonTheme {
  Dark = 'dark',
  Light = 'light',
}

type ButtonProps = {
  className?: string;
  variant?: ButtonVariant;
  text?: string;
  customIcon?: ReactNode;
  onClick: () => void;
  type?: 'submit' | 'reset' | 'button';
  theme?: ButtonTheme;
  animatedIcon?: boolean;
};

export function Button({
  className,
  variant = ButtonVariant.Full,
  theme = ButtonTheme.Light,
  text,
  customIcon,
  onClick,
  type,
  animatedIcon = false,
}: ButtonProps) {
  const [hovered, setHovered] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [changed, setChanged] = useState(false);
  const isTablet = useTabletWidth();

  const wrapperClassName = cn(
    styles.wrapper,
    {
      [styles.fit]: variant === ButtonVariant.Fit,
      [styles.dark]: theme === ButtonTheme.Dark,
    },
    className,
  );

  const buttonClassName = cn(
    styles.button,
    {
      [styles.hovered]: hovered,
      [styles.clicked]: clicked,
      [styles.animatedIcon]: animatedIcon,
    },
    className,
  );

  const textClassName = cn(styles.text, {
    [styles.changed]: changed,
  });

  const handleClick = () => {
    onClick();
  };

  const handleMouseDown = () => {
    setClicked(true);
  };

  const handleMouseUp = () => {
    setClicked(false);
  };

  const handleMouseOver = () => {
    if (isTablet) return;
    setHovered(true);
  };

  const handleMouseLeave = () => {
    if (isTablet) return;
    setHovered(false);
  };

  useEffect(() => {
    setChanged(true);
    const timeout = setTimeout(() => {
      setChanged(false);
    }, 300);

    return () => clearTimeout(timeout);
  }, [text]);

  return (
    <div
      className={wrapperClassName}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
    >
      <button className={buttonClassName} onClick={handleClick} type={type}>
        {text && <span className={textClassName}>{text}</span>}
        {customIcon && customIcon}
      </button>
    </div>
  );
}
